<template>
  <div
    id="DWAppointment"
    class=" items-center justify-center inset-0 ... MyTable"
  >
    <table style="background: white; width: 90%;margin: auto!important" class="text-center m-3 ">
      <tr class="HeaderTableColor">
        <th class="text-center">
          {{ $t("Patient") }}
        </th>
        <th class="text-center">
          {{ $t("Date") }}
        </th>
        <th class="text-center">
          <div class="MyPopClose">
            <feather-icon
              icon="XIcon"
              svgClasses="w-5 h-5 hover:text-primary stroke-current"
              @click.stop="$emit('closePop')"
            />
          </div>
          {{ $t("Time") }}
        </th>
      </tr>

      <tr v-for="(item, index) in Model" :key="index">
              <!-- <tr v-for="(item, index) in Model" :key="index" v-if="item.PaymentStatusID ==5"> -->

        <td class="text-1xl text-center">
          <p v-if="item.Patient">{{ item.Patient.Name }}</p>
        </td>
        <td class="text-1xl text-center">
          {{ item.DoctorSession.DateFormatted }}
        </td>
        <td class="text-1xl text-center">{{ item.DoctorSession.DateFrom.slice(11, 16) }}</td>
      </tr>
      <tr>
        <td></td>
        <td> <h3 v-if="Model.length==0"  class="w-full sm:w-auto text-center text-primary mt-5">{{$t("NoDataToshow")}}</h3></td>
        <td></td>
      </tr>
    </table>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain } from "@/gloabelConstant.js";

export default {
  data() {
    return {
      baseURL: domain,
      Model: [],
    };
  },
  methods: {
    ConvertDate(date) {
      debugger;
      var ms = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var temp = new Date(date);
      return (
        temp.getDate() + " " + ms[temp.getMonth()] + " " + temp.getFullYear()
      );
    },
  },
  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    debugger;

    var curr = new Date(); // get current date
    var first = curr.getDate() - curr.getDay(); // First day is the day of the month - the day of the week
    var last = first + 6; // last day is the first day + 6

    var firstday = new Date(curr.setDate(first)).toUTCString();
    var lastday = new Date(curr.setDate(last)).toUTCString();

    var Obj = {
      doctorID: this.$store.state.AppActiveUser.Doctor.ID,
      DateFrom: firstday,
      DateTo: lastday,
    };
    Obj.NotIncludeStatusIds=[3,5] //cancel,completed
    Obj.NotIncludePaymentStatusIds=[1,3] //Pending,canceled
    this.$store
      .dispatch("patientList/SearchPatientReservationSessions", Obj)
      .then((res) => {
        if (res.status == 200) {
          this.Model = res.data.Data;

        }
      })
      .catch((err) => {
        window.showError();
          window.showError(err.response.data.message);
      });
  },
};
</script>
<style>
#DWAppointment table {
  border-collapse: collapse;
 background: white;
  border-radius: 1em;
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
}

#DWAppointment td,
#DWAppointment th {
  border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
  border-collapse: collapse;
}

#DWAppointment th {
  border-top: none;
}

#DWAppointment th:first-child {
  border-top-left-radius: 20px;
  border-left: none;
  border-right: none;
}
#DWAppointment th:last-child {
  border-top-right-radius: 20px;
}
#DWAppointment td:first-child {
  border-left: none;
}

#DWAppointment tr {
  height: 5.5rem;
}
</style>
